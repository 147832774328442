import { A } from "@solidjs/router";
import { For, JSX, Show } from "solid-js";

interface Know {
  id: string;
  essence: string;
}

interface Imply {
  know?: Know | null;
  negative: boolean;
  propositions?: Know[];
}

function Linkable(props: {
  linkable: boolean;
  link: string;
  children: JSX.Element;
}) {
  return props.linkable ? (
    <A href={props.link}>{props.children}</A>
  ) : (
    props.children
  );
}

export default function ImplyHead({
  imply,
  linkable = false,
}: {
  imply: Imply;
  linkable?: boolean;
}) {
  return (
    <div class="not-prose">
      <Linkable linkable={linkable} link={`/n/${imply.know?.id}`}>
        <div class="flex items-center flex-nowrap overflow-hidden">
          <svg
            class="h-8 w-2 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 25 100"
          >
            {Array.isArray(imply.propositions) &&
              imply.propositions.length > 0 && (
                <line
                  x1="0"
                  y1="50"
                  x2="0"
                  y2="100"
                  stroke="currentColor"
                  stroke-width="16"
                />
              )}
            <line
              x1="0"
              y1="50"
              x2="25"
              y2="50"
              stroke="currentColor"
              stroke-width="8"
            />
          </svg>
          <svg
            class="h-8 w-8 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            {imply.negative ? (
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m13 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            ) : (
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 12H0m17 0-4 4m4-4-4-4"
              />
            )}
          </svg>
          <h1
            class={`pl-1 max-w-full overflow-hidden text-nowrap whitespace-nowrap text-ellipsis font-bold text-xl decoration-2 decoration-red-800 ${
              imply.negative && "  line-through"
            }`}
          >
            {imply.know?.essence}
          </h1>
        </div>
      </Linkable>
      <Show when={imply.propositions}>
        <For each={imply.propositions}>
          {(propos, idx) => (
            <div class="flex items-center box-border flex-nowrap overflow-hidden">
              <svg class="h-8 w-4 mr-2 p-0 shrink-0" viewBox="0 0 50 100">
                <line
                  x1="0"
                  y1="50"
                  x2="100"
                  y2="50"
                  stroke="currentColor"
                  stroke-width="8"
                />
                {idx() === imply.propositions!.length - 1 ? (
                  <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="50"
                    stroke="currentColor"
                    stroke-width="16"
                  />
                ) : (
                  <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100"
                    stroke="currentColor"
                    stroke-width="16"
                  />
                )}
              </svg>
              <Linkable linkable={linkable} link={`/n/${propos.id}`}>
                <h4 class="inline overflow-hidden text-nowrap whitespace-nowrap text-ellipsis">
                  {propos?.essence}
                </h4>
              </Linkable>
            </div>
          )}
        </For>
      </Show>
    </div>
  );
}
